$bg-color: #f1f5f7;

$white: #fff;
$black: #000;

$red: #F44336;
$dark-red: #d32f2f;
$light-red: #ffcdd2;

$pink: #E91E63;
$dark-pink: #C2185B;
$light-pink: #F8BBD0;

$purple: #9C27B0;
$dark-purple: #7B1FA2;
$light-purple: #E1BEE7;

$deep-purple: #673AB7;
$dark-deep-purple: #512DA8;
$light-deep-purple: #D1C4E9;

$indigo: #3F51B5;
$dark-indigo: #303F9F;
$light-indigo: #C5CAE9;

$blue: #2196F3;
$dark-blue: #1976D2;
$light-blue-blue: #BBDEFB;

$light-blue: #03A9F4;
$dark-light-blue: #0288D1;
$light-light-blue: #B3E5FC;

$cyan: #00BCD4;
$dark-cyan: #0097A7;
$light-cyan: #B2EBF2;

$teal: #009688;
$dark-teal: #00796B;
$light-teal: #B2DFDB;

$green: #4CAF50;
$dark-green: #388E3C;
$light-green: #C8E6C9;

$light-green: #8BC34A;
$dark-light-green: #689F38;
$light-light-green: #DCEDC8;

$lime: #CDDC39;
$dark-lime: #AFB42B;
$light-lime: #F0F4C3;

$yellow: #FFEB3B;
$dark-yellow: #FBC02D;
$light-yellow: #FFF9C4;

$amber: #FFC107;
$dark-amber: #FFA000;
$light-amber: #FFECB3;

$orange: #FF9800;
$dark-orange: #F57C00;
$light-orange: #FFE0B2;

$deep-orange: #FF5722;
$dark-deep-orange: #E64A19;
$light-deep-orange: #FFCCBC;

$brown: #795548;
$dark-brown: #5D4037;
$light-brown: #D7CCC8;

$grey: #9E9E9E;
$dark-grey: #616161;
$light-grey: #F5F5F5;

$blue-grey: #607D8B;
$dark-blue-grey: #455A64;
$light-blue-grey: #CFD8DC;


$secondary-color: $lime;

$default-color: #ccc;
$primary-color: #aa66cc;
$success-color: #99cc00;
$info-color: #49ceff;
$warning-color: #ffbb33;
$danger-color: #ff5f5f;

$text-color: #333;
$font: 'Roboto', sans-serif;

$border-radius: 3px;