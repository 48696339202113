h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  text-transform: uppercase;
  font-weight: 500;
}

h1, .h1{
  font-size: 36px;
}

h2, .h2{
  font-size: 30px;
}

h3, .h3{
  font-size: 24px;
}

h4, .h4{
  font-size: 18px;
}

h5, .h5{
  font-size: 15px;
}

h6, .h6{
  font-size: 12px;
}

.text-primary{
  color: $primary-color;
}

.text-success{
  color: $success-color;
}

.text-warning{
  color: $warning-color;
}

.text-info{
  color: $info-color;
}

.text-danger{
  color: $danger-color;
}
