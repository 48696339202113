/*Preloader*/
.table-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  .table-row {
    display: table-row;
    .table-cell {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

#preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  .refresh-preloader {
    display: block;
    z-index: 99;
    border-radius: 0;
  }
}

//Content preloader
.refresh-preloader {
  background: #f1f5f7;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 10;
  border-radius: $border-radius;
}



.preloader {
  position: absolute;
  top: 50%;
//  left: 50%;
//  margin-left: -35px;
  margin-top: -25px;
  height: 50px;
  width: 100%;
  overflow: hidden;
  i {
    display: block;
    width: 16px;
    height: 16px;
    background: black;
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    opacity: 0;
    transform: translate3d(150px,0,0);
    overflow: hidden;
    text-indent: -9999px;
    &:nth-child(1) {
      background: $danger-color; // Red
      animation: google 1.75s ease-in-out infinite;
    }
    &:nth-child(2) {
     background: $warning-color; // Yellow
     animation: google 1.75s ease-in-out infinite .3s;
    }
    &:nth-child(3) {
     background: $info-color; // Blue
     animation: google 1.75s ease-in-out infinite .6s;
    }
  }
}

@keyframes google {
  0%   { opacity: 0;  transform: translate3d(150px,0,0);}
  30%  { opacity: 1;  transform: translate3d(0,0,0);}
  70%  { opacity: 0.8;  transform: translate3d(0,0,0);}
  100% { opacity: 0;  transform: translate3d(-300px,0,0);}
}

