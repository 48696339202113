/*Skins*/

body.p-red .primary, body.s-red .secondary, body.n-red nav.navbar{
  background: $red; 
}

body.p-pink .primary, body.s-pink .secondary, body.n-pink nav.navbar{
  background: $pink; 
}

body.p-purple .primary, body.s-purple .secondary, body.n-purple nav.navbar{
  background: $purple; 
}

body.p-deep-purple .primary, body.s-deep-purple .secondary, body.n-deep-purple nav.navbar{
  background: $deep-purple; 
}

body.p-indigo .primary, body.s-indigo .secondary, body.n-indigo nav.navbar{
  background: $indigo; 
}

body.p-blue .primary, body.s-blue .secondary, body.n-blue nav.navbar{
  background: $blue; 
}

body.p-light-blue .primary, body.s-light-blue .secondary, body.n-light-blue nav.navbar{
  background: $light-blue; 
}

body.p-cyan .primary, body.s-cyan .secondary, body.n-cyan nav.navbar{
  background: $cyan; 
}

body.p-teal .primary, body.s-teal .secondary, body.n-teal nav.navbar{
  background: $teal; 
}

body.p-green .primary, body.s-green .secondary, body.n-green nav.navbar{
  background: $green; 
}

body.p-light-green .primary, body.s-light-green .secondary, body.n-light-green nav.navbar{
  background: $light-green; 
}

body.p-lime .primary, body.s-lime .secondary, body.n-lime nav.navbar{
  background: $lime; 
}

body.p-yellow .primary, body.s-yellow .secondary, body.n-yellow nav.navbar{
  background: $yellow; 
}

body.p-amber .primary, body.s-amber .secondary, body.n-amber nav.navbar{
  background: $amber; 
}

body.p-orange .primary, body.s-orange .secondary, body.n-orange nav.navbar{
  background: $orange; 
}

body.p-deep-orange .primary, body.s-deep-orange .secondary, body.n-deep-orange nav.navbar{
  background: $deep-orange; 
}

body.p-brown .primary, body.s-brown .secondary, body.n-brown nav.navbar{
  background: $brown; 
}

body.p-grey .primary, body.s-grey .secondary, body.n-grey nav.navbar{
  background: $grey; 
}

body.p-blue-grey .primary, body.s-blue-grey .secondary, body.n-blue-grey nav.navbar{
  background: $blue-grey; 
}

body.n-red .searchbox-icon, body.n-red .searchbox-submit{
  background: $red; 
}

body.n-pink .searchbox-icon, body.n-pink .searchbox-submit{
  background: $pink; 
}

body.n-purple .searchbox-icon, body.n-purple .searchbox-submit{
  background: $purple; 
}

body.n-deep-purple .searchbox-icon, body.n-deep-purple .searchbox-submit{
  background: $deep-purple; 
}

body.n-indigo .searchbox-icon, body.n-indigo .searchbox-submit{
  background: $indigo; 
}

body.n-blue .searchbox-icon, body.n-blue .searchbox-submit{
  background: $blue; 
}

body.n-light-blue .searchbox-icon, body.n-light-blue .searchbox-submit{
  background: $light-blue; 
}

body.n-cyan .searchbox-icon, body.n-cyan .searchbox-submit{
  background: $cyan; 
}

body.n-teal .searchbox-icon, body.n-teal .searchbox-submit{
  background: $teal; 
}

body.n-green .searchbox-icon, body.n-green .searchbox-submit{
  background: $green; 
}

body.n-light-green .searchbox-icon, body.n-light-green .searchbox-submit{
  background: $light-green; 
}

body.n-lime .searchbox-icon, body.n-lime .searchbox-submit{
  background: $lime; 
}

body.n-yellow .searchbox-icon, body.n-yellow .searchbox-submit{
  background: $yellow; 
}

body.n-red .searchbox-icon, body.n-red .searchbox-submit{
  background: $red; 
}

body.n-amber .searchbox-icon, body.n-amber .searchbox-submit{
  background: $amber; 
}

body.n-orange .searchbox-icon, body.n-orange .searchbox-submit{
  background: $orange; 
}

body.n-deep-orange .searchbox-icon, body.n-deep-orange .searchbox-submit{
  background: $deep-orange; 
}

body.n-grey .searchbox-icon, body.n-grey .searchbox-submit{
  background: $grey; 
}

body.n-brown .searchbox-icon, body.n-brownd .searchbox-submit{
  background: $brown; 
}

body.n-blue-grey .searchbox-icon, body.n-blue-grey .searchbox-submit{
  background: $blue-grey; 
}

